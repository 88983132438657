import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Em, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 90px 0",
	"sm-padding": "74px 0 74px 0",
	"quarkly-title": "Footer",
	"color": "--dark"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"md-justify-content": "center",
			"md-align-items": "center",
			"md-padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "33.333%",
			"display": "flex"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--light",
			"sm-text-align": "center",
			"children": <Em
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				@ Copyright Adventure Peak Hub
			</Em>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"md-padding": "16px 16px 16px 16px",
			"empty-min-width": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "33.333%",
			"display": "flex",
			"md-align-items": "center",
			"md-justify-content": "center",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"sm-text-align": "center",
			"md-text-align": "center",
			"font": "--headline3",
			"children": <>
				0678926688
				<br />
				info@adventurepeakhub.com
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"width": "33.333%",
			"md-justify-content": "center",
			"md-padding": "16px 16px 16px 16px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"display": "flex",
			"md-align-items": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"sm-text-align": "center",
			"md-text-align": "center",
			"font": "--headline3",
			"children": "вулиця Грушевського, 147, Ясіня"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" md-align-items="center" flex-direction="row" md-flex-direction="column" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;