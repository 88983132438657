import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ADVENTURE PEAK HUB
			</title>
			<meta name={"description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:title"} content={"ADVENTURE PEAK HUB"} />
			<meta property={"og:description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
		</Helmet>
		<Components.Heder2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
		</Components.Heder2>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(36, 26, 26, 0.74) 0%,rgba(36, 26, 26, 0.77) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/44cd635336016e024b244cba8635dae0.jpg?v=2024-11-26T11:19:08.040Z) bottom/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="--lead"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
						text-transform="uppercase"
					>
						Відчуйте дух справжньої пригоди в серці гір
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						border-radius="8px"
						font="--lead"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/about"
					>
						Послуги
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="--lead"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Ідеальний вибір для відпочинку, де природа та комфорт створюють гармонію
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				md-width="100%"
			>
				<Text margin="0px 0px 0 0px" color="--light" font="--base">
					Ми пропонуємо затишні кімнати, смачну домашню кухню та безпосередній доступ до гірськолижних трас різного рівня складності.
					<br />
					<br />
					<br />
					Проведіть незабутній час у горах разом з нами! Забронюйте ваш номер вже сьогодні та розпочніть свою пригоду в Adventure Peak Hub.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
				width="100%"
			>
				<Text
					font="--headline2"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 1rem 0px"
				>
					Чому обирають нас?
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11:19:08.063Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							srcSet="https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/284912920.jpg?v=2024-11-26T11%3A19%3A08.063Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Затишок
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".9">
							Тепла атмосфера, оформлена в стилі альпійських будиночків, забезпечить вам незабутній відпочинок.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="2 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							srcSet="https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11%3A19%3A08.059Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Локація
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".9">
							Наш будиночок розташований за кілька кроків від популярних схилів, тож ви можете першими насолоджуватися свіжим снігом.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11:19:08.071Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							srcSet="https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11%3A19%3A08.071Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Додаткові активності
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".9">
							Окрім катання, ви можете дослідити гірські маршрути, зайнятися зимовим трекінгом чи розслабитися у нашій сауні.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="3 / span 1"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11:19:08.055Z"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
							srcSet="https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/674595351b94b1002432ddd3/images/image.webp?v=2024-11-26T11%3A19%3A08.055Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Сервіс
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".9">
							Ми дбаємо про ваш комфорт – від трансферу до обладнання для зимового спорту.
						</Text>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Components.Cta23 />
		<Components.Faq />
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});