import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "100px 0 100px 0",
	"background": "--color-darkL1",
	"quarkly-title": "FAQ-7"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": <Strong>
				FAQ (Часті питання)
			</Strong>
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "50px 50px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"md-grid-template-columns": "1fr",
			"sm-grid-gap": "35px 0"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Як доїхати до Adventure Peak Hub?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Ми знаходимося лише за 15 км від найближчого міста, а наш трансфер доставить вас прямо до дверей будиночка. Також можна скористатися таксі чи громадським транспортом."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Чи потрібно заздалегідь бронювати послуги?"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Так, ми рекомендуємо бронювати проживання та додаткові послуги заздалегідь, особливо під час зимового сезону."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Чи можна привозити домашніх тварин?"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Так, ми раді вітати гостей із домашніми улюбленцями. За додаткову плату ми забезпечимо зручності для вашого чотирилапого друга."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Чи підходить ваш будиночок для сімей із дітьми?"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Звісно! У нас є дитячі ліжечка, спеціальне меню та простір для активних ігор на свіжому повітрі."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Чи є у вас доступ до Wi-Fi?"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Так, безкоштовний Wi-Fi доступний на всій території будиночка."
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 25px/1.2 --fontFamily-sans",
			"color": "--light",
			"children": "Що робити, якщо я вперше катаюся на лижах чи сноуборді?"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "#b2bac1",
			"children": "Ми надамо вам усе необхідне спорядження, а наші інструктори допоможуть зробити ваш перший досвід на схилах незабутнім."
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text1")} />
				<Text {...override("text2")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text5")} />
				<Text {...override("text6")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text7")} />
				<Text {...override("text8")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text9")} />
				<Text {...override("text10")} />
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text11")} />
				<Text {...override("text12")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;