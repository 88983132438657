import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "40px 0 40px 0",
	"quarkly-title": "Header",
	"box-shadow": "--l"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-color": "LightGray",
			"width": "30%",
			"align-items": "center",
			"lg-width": "50%",
			"empty-min-height": "64px",
			"empty-border-style": "solid",
			"display": "flex",
			"sm-width": "70%",
			"empty-min-width": "64px",
			"empty-border-width": "1px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"text-transform": "uppercase",
			"children": "Adventure Peak Hub"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"sm-width": "30%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-style": "solid",
			"display": "flex",
			"align-items": "center",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"width": "70%",
			"lg-width": "50%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"md-padding": "20px 0px 20px 0px",
			"display": "flex",
			"justify-content": "flex-end",
			"align-items": "center",
			"md-flex-direction": "column",
			"margin": "0px 0px 0px auto",
			"lg-justify-content": "space-between",
			"lg-width": "100%",
			"lg-flex-direction": "column",
			"lg-align-items": "flex-end"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--lightD1",
			"font": "--base",
			"children": "Головна",
			"href": "/index"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"md-margin": "20px 0px 0px 0px",
			"text-decoration-line": "initial",
			"color": "--lightD1",
			"font": "--base",
			"margin": "0px 0px 0px 20px",
			"children": "Про нас та послуги",
			"href": "/about"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--lightD1",
			"font": "--base",
			"margin": "0px 0px 0px 20px",
			"md-margin": "20px 0px 0px 0px",
			"text-decoration-line": "initial",
			"href": "/contacts",
			"children": "Контакти"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"color": "--lightD1",
			"font": "--base",
			"margin": "0px 0px 0px 20px",
			"md-margin": "20px 0px 0px 0px",
			"text-decoration-line": "initial",
			"href": "/politika-konfidencijnosti",
			"children": "Політика Конфіденційності"
		}
	},
	"SectionContent": {
		"props": {
			"lg-flex-direction": "column"
		}
	}
};

const Heder2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			align-items="center"
			display="flex"
			justify-content="space-between"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Heder2, { ...Section,
	defaultProps,
	overrides
});
export default Heder2;