import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0",
	"background": "--color-dark"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between",
			"md-width": "100%",
			"padding": "0px 80px 0px 0px",
			"lg-padding": "0px 30px 0px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline2",
			"md-margin": "0px 0px 30px 0px",
			"children": "Дозвольте собі більше"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "--light",
			"font": "--base",
			"children": <>
				Забронюйте ваш ідеальний зимовий відпочинок в Adventure Peak Hub уже зараз! Ми чекаємо на вас із гарячим чаєм і неймовірними враженнями.
				<br />
				<br />
				Проведіть незабутній час у горах разом з нами! Забронюйте ваш номер вже сьогодні та розпочніть свою пригоду в Adventure Peak Hub.
			</>
		}
	}
};

const Cta23 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta23, { ...Section,
	defaultProps,
	overrides
});
export default Cta23;