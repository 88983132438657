import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				ADVENTURE PEAK HUB
			</title>
			<meta name={"description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:title"} content={"ADVENTURE PEAK HUB"} />
			<meta property={"og:description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
		</Helmet>
		<Components.Heder2 />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(36, 26, 26, 0.74) 0%,rgba(36, 26, 26, 0.77) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/44cd635336016e024b244cba8635dae0.jpg?v=2024-11-26T11:19:08.040Z) bottom/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="--lead"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
						text-transform="uppercase"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							контакти
						</Strong>
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Плануйте ваш незабутній відпочинок із комфортом і вигодою! Забронюйте місце в Adventure Peak Hub уже сьогодні.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						border-radius="8px"
						font="--lead"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/index"
					>
						Головна
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="--lead"
						type="link"
						href="/about"
						text-decoration-line="initial"
					>
						Послуги
					</Button>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					вулиця Грушевського, 147, Ясіня
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					0678926688
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					info@adventurepeakhub.com
				</Text>
			</Box>
		</Section>
		<Components.Cta23 />
		<Components.Faq />
		<Components.Footer>
			<Override slot="text1">
				0678926688
				<br />
				info@adventurepeakhub.com
			</Override>
			<Override slot="text2">
				вулиця Грушевського, 147, Ясіня
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});