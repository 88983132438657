import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				ADVENTURE PEAK HUB
			</title>
			<meta name={"description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:title"} content={"ADVENTURE PEAK HUB"} />
			<meta property={"og:description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
		</Helmet>
		<Components.Heder2>
			<Override slot="text">
				Adventure Peak Hub
			</Override>
		</Components.Heder2>
		<Section padding="50px 0 0 0" text-align="center" color="#ffffff">
			<Text as="h1" font="--headline1" margin="0 0 16px 0">
				Політика Конфіденційності
			</Text>
		</Section>
		<Section background="--color-dark" padding="40px 0" color="#e0e0e0" font="--base">
			<Box max-width="980px" margin="0 auto" width="100%">
				<Text as="h2" font="--headline2" margin="16px 0">
					Вступ
				</Text>
				<Text margin="16px 0">
					Ласкаво просимо до сайту ADVENTURE PEAK HUB. Ми зобов’язані
			захищати вашу конфіденційність і хочемо, щоб ви
			розуміли, які дані ми збираємо, як ми їх використовуємо
			та з якою метою.
				</Text>
				<Text as="h2" font="--headline2" margin="16px 0">
					Збір інформації
				</Text>
				<Text margin="16px 0">
					Ми можемо збирати такі дані:
				</Text>
				<Box as="div" display="flex" flex-direction="column" padding-left="20px">
					<Text margin="8px 0">
						- Ваше ім'я та контактні дані.
					</Text>
					<Text margin="8px 0">
						- Інформацію про вашу активність на сайті.
					</Text>
					<Text margin="8px 0">
						- Дані для обробки замовлень (якщо такі є).
					</Text>
				</Box>
				<Text as="h2" font="--headline2" margin="16px 0">
					Використання інформації
				</Text>
				<Text margin="16px 0">
					Ми використовуємо ваші дані для:
				</Text>
				<Box as="div" display="flex" flex-direction="column" padding-left="20px">
					<Text margin="8px 0">
						- Надання послуг і обробки замовлень.
					</Text>
					<Text margin="8px 0">
						- Покращення роботи сайту.
					</Text>
					<Text margin="8px 0">
						- Надсилання інформаційних матеріалів (за згодою).
					</Text>
				</Box>
				<Text as="h2" font="--headline2" margin="16px 0">
					Обмін даними
				</Text>
				<Text margin="16px 0">
					Ми не передаємо ваші дані третім особам, за винятком
			випадків, коли це вимагається законом або ви дали свою
			згоду.
				</Text>
				<Text as="h2" font="--headline2" margin="16px 0">
					Ваші права
				</Text>
				<Text margin="16px 0">
					Ви маєте право знати, як ми використовуємо ваші дані, і
			можете звернутися до нас для їх редагування або
			видалення.
				</Text>
				<Text as="h2" font="--headline2" margin="16px 0">
					Зв'яжіться з нами
				</Text>
				<Text margin="16px 0">
					Якщо у вас є питання щодо нашої Політики
			Конфіденційності, напишіть нам на info@adventurepeakhub.com.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1">
				0678926688
				<br />
				info@adventurepeakhub.com
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});