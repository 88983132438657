import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Button, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				ADVENTURE PEAK HUB
			</title>
			<meta name={"description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:title"} content={"ADVENTURE PEAK HUB"} />
			<meta property={"og:description"} content={"Наш будиночок, розташований поруч із мальовничими схилами для лижників і сноубордистів, ідеально підходить для любителів активного відпочинку та спокійних гірських краєвидів."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z"} />
		</Helmet>
		<Components.Heder2>
			<Override slot="SectionContent" />
			<Override slot="box2" />
		</Components.Heder2>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(36, 26, 26, 0.74) 0%,rgba(36, 26, 26, 0.77) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/44cd635336016e024b244cba8635dae0.jpg?v=2024-11-26T11:19:08.040Z) bottom/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="--lead"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
						text-transform="uppercase"
					>
						<Strong>
							Послуги та ціни
						</Strong>
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline2"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					Adventure Peak Hub пропонує широкий спектр послуг для вашого комфорту та насолоди гірським відпочинком
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						border-radius="8px"
						font="--lead"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="--color-primary"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/index"
					>
						Головна
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="--lead"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-8">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(0deg,rgba(7, 11, 57, 0.15) 0%,--color-darkL1 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/image%20%281%29.webp?v=2024-11-26T11:19:08.083Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							<Strong>
								Проживання
							</Strong>
						</Text>
						<Text
							font="base"
							color="--color-light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Затишні номери на будь-який смак: стандартні кімнати, сімейні апартаменти, номери люкс із панорамним видом на гори.
							<br />
							<br />
							<br />
							Усі номери обладнані:
							<br />
							<br />
							Зручними ліжками з гіпоалергенними постільними комплектами.
							<br />
							<br />
							Власними ванними кімнатами.
							<br />
							<br />
							Сучасними зручностями: Wi-Fi, телевізором, електрочайником.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(00deg,rgba(7, 11, 57, 0.15) 0%,--color-darkL1 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1648726445011-9fbf3a5ddb90?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Харчування
						</Text>
						<Text
							font="base"
							color="--color-light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Сніданки включені у вартість проживання.
							<br />
							<br />
							Ресторан із різноманітним меню: місцеві страви, вегетаріанські та дитячі страви.
							<br />
							<br />
							Гарячі напої та закуски доступні протягом дня.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(0,rgba(7, 11, 57, 0.15) 0%,--color-darkL1 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/1516614936_dragobrat-10.jpg?v=2024-11-26T11:19:08.071Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Гірськолижні послуги
							</Strong>
							<br />
						</Text>
						<Text
							font="base"
							color="--color-light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Прокат спорядження
							</Strong>
							:
							<br />
							<br />
							Лижний комплект (лижі, черевики, палиці) –{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								300 грн/день
							</Strong>
							.
							<br />
							<br />
							Сноуборд –{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								350 грн/день
							</Strong>
							.
							<br />
							<br />
							Каска –{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								50 грн/день
							</Strong>
							.
							<br />
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Інструкторські послуги
							</Strong>
							:
							<br />
							<br />
							Індивідуальне заняття –{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								600 грн/год
							</Strong>
							.
							<br />
							<br />
							Групове заняття (до 4 осіб) –{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								400 грн/особа
							</Strong>
							.
							<br />
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Зберігання спорядження
							</Strong>
							{" "}–{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								50 грн/день
							</Strong>
							.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,--color-darkL1 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/chany_v_karpatah.jpg?v=2024-11-26T11:19:08.065Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							<Strong>
								Релакс та активності
							</Strong>
							<br />
							<br />
						</Text>
						<Text
							font="base"
							color="--color-light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							<Strong>
								Сауна на дровах
							</Strong>
							{" "}(до 4 осіб) –{" "}
							<Strong>
								500 грн/год
							</Strong>
							.
							<br />
							<br />
							<Strong>
								Прогулянки засніженими стежками
							</Strong>
							{" "}–{" "}
							<Strong>
								безкоштовно для гостей будиночка
							</Strong>
							.
							<br />
							<br />
							<Strong>
								Катання на снігоходах
							</Strong>
							{" "}–{" "}
							<Strong>
								від 1000 грн/год
							</Strong>
							.
							<br />
							<br />
							<Strong>
								Організація екскурсій
							</Strong>
							{" "}(групові чи індивідуальні) –{" "}
							<Strong>
								від 800 грн/екскурсія
							</Strong>
							.
							<br />
							<br />
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,--color-darkL1 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/674595351b94b1002432ddd3/images/Koly-varto-poikhaty-v-Drahobrat.jpg?v=2024-11-26T11:19:08.059Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Спеціальні пропозиції
							<br />
							<br />
						</Text>
						<Text
							font="base"
							color="--color-light"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							<Strong>
								Знижка 10%
							</Strong>
							{" "}при бронюванні на 5 і більше ночей.
							<br />
							<br />
							<Strong>
								Пакет "Зимова пригода"
							</Strong>
							{" "}(проживання, сніданки, оренда спорядження та сеанс у сауні) –{" "}
							<Strong>
								3500 грн/день
							</Strong>
							.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Faq />
		<Components.Cta23 />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});